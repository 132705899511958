import Vue from 'vue'
import Router from 'vue-router'
import store from '@/store'
import VueCookie from 'vue-cookie'

const routerOptions = [

    // ================================================ Root =======================================================

    // ================================================ Profile =======================================================
    // { path: '/', name: 'MainDashboard', componentPath: 'HelloWorld' },
    // { path: '/', name: 'ManagerDashboard', componentPath: 'Expense/Dashboards/ManagerExpenseDashboard' },
    // { path: '/', name: 'Dashboard', componentPath: 'HelloWorld' },
    { path: '/', name: 'HomeDashboard', componentPath: 'Dashboard/HomeDashboard', meta: { requiresAuth: true }},
    // { path: '/dashboard', name: 'Dashboard', componentPath: 'HelloWorld' },

    // ==============  Employee Info ====================//
    // Employee
    { path: "/employee_info/list", name: "Employee Info List", componentPath: 'Employee/EmployeeListGridView', meta: { requiresAuth: true }},
    { path: "/employee_info/payrolls/list", name: "Payrolls List", componentPath: 'Employee/PayrollListView', meta: { requiresAuth: true }},
    // { path: "/payslip/", name: "Payslip", componentPath: 'Employee/PayslipGenerate'},
    // { path: "/payslip/edit/:id", name: "Payslip", componentPath: 'Employee/PayslipGenerate'},
    { path: "/payslip/view/:id", name: "Payslip", componentPath: 'Employee/PayslipGenerate', meta: { requiresAuth: true }},
    { path: "/employee_info/timesheet/list", name: "Timesheet List", componentPath: 'Employee/TimeSheetListView', meta: { requiresAuth: true }},
    { path: "/employee_info/create", name: "Employee Create", componentPath: 'Employee/CreateEditEmployee', meta: { requiresAuth: true }},
    { path: "/employee_info/edit/:id/:my_profile/:is_draft", name: "Employee Edit", componentPath: 'Employee/CreateEditEmployee', meta: { requiresAuth: true }},
    { path: "/employee_info/view/:id/:my_profile/:is_draft", name: "Employee View", componentPath: 'Employee/CreateEditEmployee', meta: { requiresAuth: true }},
    { path: "/employee_info/myshift_details/:details_type", name: "Myshift Details", componentPath: 'Employee/MyShiftDetails', meta: { requiresAuth: true }},
    // Payroll
    { path: "/employee_info/payroll", name: "Employee Payroll", componentPath: 'Home', meta: { requiresAuth: true }},
    // Asset Details
    { path: "/employee_info/asset_details", name: "Employee Asset Details", componentPath: 'Home', meta: { requiresAuth: true }},
    // Attendance List
    { path: "/employee_info/attendance_list", name: "Employee Attendance", componentPath: 'Employee/EmployeeAttendanceListView', meta: { requiresAuth: true }},
    { path: "/employee_info/hr_attendance/:id", name: "HR Attendance", componentPath: 'Employee/attendanceListView', meta: { requiresAuth: true }},
    //Leave List
    // { path: "/employee_info/leave_list", name: "Employee Leave", componentPath: 'Employee/Leave/leaveListView'},
    { path: "/employee_info/leave_list", name: "Employee Leave Split View", componentPath: 'Employee/Leave/LeaveSplitView', meta: { requiresAuth: true }},
    { path: "/employee_info/leave_log/:leave_type", name: "Employee Leave log", componentPath: 'Employee/Leave/LeaveLogSplitView', meta: { requiresAuth: true }},
    { path: "/employee_info/overtime_log/:ot_type", name: "Overtime Request Log", componentPath: 'Employee/Overtime/OvertimeSplitView', meta: { requiresAuth: true }},
    { path: "/employee_info/employee_leave_list", name: "Employee Leave List", componentPath: 'Employee/Leave/EmployeeLeaveListView', meta: { requiresAuth: true }},
    { path: "/employee_info/view_leave", name: "Employee Leave", componentPath: 'Employee/Leave/ViewLeave', meta: { requiresAuth: true }},
    // { path: "/employee_info/hr_attendance/:id", name: "HR Attendance", componentPath: 'Employee/attendanceListView'},
    // Assets List
    { path: "/employee_info/assets_list", name: "Employee Assets", componentPath: 'Employee/AssetListView', meta: { requiresAuth: true }},
    
    // Email Redirection
    { path: "/employee_info/overtime_log_details/:comp_id/:user_id/:id", name: "Employee Overtime log Details" , componentPath: 'Employee/ViewSwitchCompany', meta: { requiresAuth: true }},
    { path: "/employee_info/leave_request_details/:comp_id/:user_id/:id", name: "Employee Leave log Details", componentPath: 'Employee/ViewSwitchCompany', meta: { requiresAuth: true }},

    // ==============  Job Portal ====================//

    { path: "/job_portal", name: "Job Portal", componentPath: 'HelloWorld', meta: { requiresAuth: true }},

    // ==============  Policies ====================//

    { path: "/policies", name: "Policies", componentPath: 'HelloWorld', meta: { requiresAuth: true }},

    // ==============  Settings ====================//

    { path: "/settings", name: "Settings", componentPath: 'Settings/Settings', meta: { requiresAuth: true }},
    { path: "/settings/view_holidays_data", name: "Settings", componentPath: 'Employee/ViewEmployeeHolidays', meta: { requiresAuth: true }},

    // ==============  Login and Verification ====================//

    { path: "/login", name: "Login", componentPath: 'Login/Login', meta: { requiresAuth: false }},

    // { path: "/user/verify", name: "VerifyUser", componentPath: 'VerifyUser' },
    { path: "/verifyOtp", name: "OtpVerify", componentPath: 'OtpVerify', meta: { requiresAuth: false } },

    // Access Denied
    // { path: '/subscription', name: 'AccessDenied', componentPath: 'AccessDenied'},





    // ***** Use other routes above this line only if you have a specific need *****


    // ================================================= Page Not Found ==============================================================
    {path:'/pagenotfound', name:'PageNotFound', componentPath: 'PageNotFound', meta: { requiresAuth: false }},

    {path:'*', name:'PageNotFoundError', redirect: '/pagenotfound', meta: { requiresAuth: false }}
]

const routes = routerOptions.map(route => {
    return {
        ...route,
        component: () => import(`@/components/${route.componentPath}.vue`)
    }
})

Vue.use(Router)

function isAuthenticated() {
    return VueCookie.get("Token") != null && VueCookie.get("Token") != ""
}

const router = new Router({
    routes,
    base: process.env.BASE_URL,
    mode: 'history'
})

// router.beforeEach((to, from, next) => {
//     if (to.matched.some(record => record.meta.requiresAuth)) {
//         if (!isAuthenticated()) {
//             next({
//                 path: '/login',
//             });
//         } else {
//             if(to.name == "Login" && isAuthenticated()){
//                 next({
//                     path: '/'
//                 });
//             }
//             next();
//         }
//     } else {
//         if(to.name == "Login" && isAuthenticated()){
//             next({
//                 path: '/'
//             });
//         }
//         next()
//     }
// });

export default router;