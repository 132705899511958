// =========================================== Library Import ========================================
import Vue from "vue";

// =========================================== Component Import ========================================
import App from "./App";
import './registerServiceWorker';

import VueSignaturePad from 'vue-signature-pad';
// ============================================ JS Import ==============================================================
import router from "./router";
import store from './store';
import moment_timezone from "moment-timezone";
import VueOtpInput from "@bachdgvn/vue-otp-input";
import DatePicker from "vue2-datepicker";
import 'vue2-datepicker/index.css';
import ElementUI from "element-ui";
import "element-ui/lib/theme-chalk/index.css";
import lang from "element-ui/lib/locale/lang/en";
import locale from "element-ui/lib/locale";
import VeeValidate from "vee-validate";
import Multiselect from "vue-multiselect";
import "vue-multiselect/dist/vue-multiselect.min.css";
import VuePhoneNumberInput from "vue-phone-number-input";
import "vue-phone-number-input/dist/vue-phone-number-input.css";
import vSelect from "vue-select";
import {HollowDotsSpinner} from "epic-spinners";
import VueTelInput from "vue-tel-input";
import { LocationPicker } from "vue2-location-picker";
import * as VueGoogleMaps from "vue2-google-maps";
import axios from "axios";
import Vuex from "vuex";
var VueUploadComponent = require('vue-upload-component');
import ProgressBar from "vue-simple-progress";
import VueObserveVisibility from "vue-observe-visibility";
import VModal from "vue-js-modal";
import { SweetModal, SweetModalTab } from "sweet-modal-vue";
import VueSweetalert2 from "vue-sweetalert2";
import "sweetalert2/dist/sweetalert2.min.css";
import global from './components/global';
import VueSlider from 'vue-slider-component'
import 'vue-slider-component/theme/default.css'
import Toasted from 'vue-toasted'
import vueDebounce from 'vue-debounce'
import vueFilterPrettyBytes from 'vue-filter-pretty-bytes'
import VueDragDrop from 'vue-drag-drop';
import fullscreen from "vue-fullscreen";
import Avatar from 'vue-avatar'
import VuejsDialog from 'vuejs-dialog';
import VueQRCodeComponent from 'vue-qr-generator'
import 'vuejs-dialog/dist/vuejs-dialog.min.css';
import VueMask from 'v-mask'
import moment from 'moment'


// import '@/components/menubar-library/shared-navbar'
// import '@/components/menubar-library/style.css'

Vue.use(VueMask);
Vue.config.productionTip = false
Vue.prototype.moment_timezone = moment_timezone;
Vue.prototype.moment = moment
Vue.component("date-picker", DatePicker);
var VueCookie = require("vue-cookie");
Vue.use(VueSignaturePad);
Vue.use(VueCookie);
Vue.use(ElementUI);
Vue.use(VeeValidate);
Vue.use(VModal);
Vue.component("multiselect", Multiselect);
Vue.component("v-select", vSelect);
Vue.component("vue-tel-input", VueTelInput);
Vue.component("v-otp-input", VueOtpInput);
Vue.component("vue-phone-number-input", VuePhoneNumberInput);
Vue.component('qr-code', VueQRCodeComponent);
Vue.component("progress-bar", ProgressBar);
Vue.component('file-upload', VueUploadComponent)
Vue.component("hollow-dots-spinner", HollowDotsSpinner);
import VueTextareaAutosize from "vue-textarea-autosize";
Vue.use(VuePhoneNumberInput);
Vue.use(VueObserveVisibility);
Vue.use(VueTelInput);
Vue.use(Vuex);
Vue.use(VueTextareaAutosize);
Vue.use(SweetModal);
Vue.use(SweetModalTab);
Vue.use(Avatar)
Vue.use(VueSweetalert2);
Vue.use(vueDebounce);
Vue.use(VueDragDrop);
Vue.use(VuejsDialog);
// Vue.component('SharedNavbar',SharedNavbar);


Vue.component('file-upload', VueUploadComponent)
Vue.component('VueSlider', VueSlider)

Vue.config.productionTip = false;
locale.use(lang);
Vue.prototype.$serverUrl = global.HRMS_API;
var token = Vue.cookie.get("Token");
Vue.prototype.$http = axios.create({
  baseURL: Vue.prototype.$serverUrl
});
Vue.use(LocationPicker, {
  installComponents: false, // If true, create it globally
});
Vue.use(VueGoogleMaps, {
  load: {
    key: "AIzaSyCQSEwELtCrUrLSfsqU0wX7QAHbLVQzUrY",
    libraries: "places", // This is required if you use the Autocomplete plugin
  },
});
Vue.use(Toasted);
Vue.use(vueFilterPrettyBytes)
Vue.use(fullscreen);

Vue.toasted.register(
  'success',
  message => {
      return message || ''
  }, {
      type: 'success',
      position: 'top-center',
      duration: 2000,
      className: 'success_snack',
      closeOnSwipe: true
  }
)
Vue.toasted.register(
  'error',
  message => {
      return message || ''
  }, {
      type: 'error',
      position: 'top-center',
      duration: 2000,
      className: 'error_snack',
      closeOnSwipe: true
  }
)

DatePicker.methods.displayPopup = function () {
  this.position = {
    top: '100%',
    left: 0,
    bottom: '100%'
  }
}

new Vue({
  router,
  store,
  components: {
    SweetModal,
    SweetModalTab,
    LocationPicker
  },
  data() {
    return {
      loggedIn: false,
    };
  },
  render: h => h(App)
}).$mount('#app')
